import { FlexGrid, SelectionMode, HeadersVisibility } from '@grapecity/wijmo.grid';
import * as wjr from '@grapecity/wijmo.react.grid';
import { useNavigate } from 'react-router-dom';

import { urljoin } from '@/utils/urljoin';

import { BaseFlexGrid, BaseFlexGridProps } from './BaseFlexGrid';

export type ListFlexGridProps = BaseFlexGridProps & {
  linkKey?: string;
  otherLinkKey?: { key: string; route: string; custom?: (key: any) => string };
  customLinkKey?: (item: any) => string | undefined;
  customRoute?: { route: string; genRouteArgs: (item?: any) => any };
  autoSize?: boolean;
  noFix?: boolean;
};

export const ListFlexGrid = function ListFlexGridComponent(props: ListFlexGridProps) {
  const navigate = useNavigate();
  const newProps = {
    ...props,
    initialized: (s: FlexGrid): void => {
      if (props?.linkKey) {
        const host = s.hostElement;
        host.addEventListener('dblclick', () => {
          const url = urljoin(
            window.location.href,
            `./${s.selectedItems[0][props.linkKey as string]}`
          );
          window.open(url, '_blank');
        });
      }
      if (props.otherLinkKey) {
        const host = s.hostElement;
        host.addEventListener('dblclick', () => {
          let key = s.selectedItems[0][props.otherLinkKey?.key as string];
          if (props.otherLinkKey?.custom) {
            key = props.otherLinkKey.custom(key);
          }
          const link = `${props.otherLinkKey?.route}${key}`;
          const url = urljoin(window.location.href, link ?? '');
          window.open(url, '_blank');
        });
      }
      if (props.customLinkKey) {
        const createLink = props.customLinkKey;
        const host = s.hostElement;
        host.addEventListener('dblclick', () => {
          const link = createLink(s.selectedItems[0]);
          const url = urljoin(window.location.href, link ?? '');
          if (url) {
            window.open(url, '_blank');
          }
        });
      }
      if (props.customRoute) {
        const host = s.hostElement;
        host.addEventListener('dblclick', () => {
          if (props.customRoute?.route) {
            const state = props.customRoute.genRouteArgs(s.selectedItems[0]);
            navigate(props.customRoute.route, { state: state });
          }
        });
      }
      props.initialized?.call(null, s);
    },
    loadedRows: (s: FlexGrid): void => {
      if (props.autoSize) s.autoSizeColumns();
      props.loadedRows?.call(null, s);
    },
    updatingLayout: (s: FlexGrid): void => {
      props.updatingLayout?.call(null, s);
    },
    updatedLayout: (s: FlexGrid): void => {
      props.updatedLayout?.call(null, s);
    },
    selectionMode: props.selectionMode ? props.selectionMode : SelectionMode.Row,
    headersVisibility: HeadersVisibility.Column,
  };

  return (
    <>
      {props.noFix ? (
        <wjr.FlexGrid {...newProps}>{newProps.children}</wjr.FlexGrid>
      ) : (
        <BaseFlexGrid {...newProps}>{newProps.children}</BaseFlexGrid>
      )}
    </>
  );
};
