export const TagsKey = [
  {
    label: '新商品',
    value: 'new',
  },
  {
    label: '在庫残りわずか',
    value: 'only_remaining',
  },
  {
    label: '欠品中',
    value: 'out_of_stock',
  },
  {
    label: '特価',
    value: 'special_price',
  },
  {
    label: '廃番',
    value: 'no_repeat',
  },
  {
    label: '滞留在庫',
    value: 'retained_stock',
  },
];

export const TAGS_NEW_LABEL = '新商品';
export const TAGS_ONLY_REMAINING_LABEL = '在庫残りわずか';
export const TAGS_OUT_OF_STOCK_LABEL = '欠品中';
export const TAGS_SPECIAL_PRICE_LABEL = '特価';
export const TAGS_NO_REPEAT_LABEL = '廃番';
export const TAGS_RETAINED_STOCK_LABEL = '滞留在庫';

export const TagsNew = [
  {
    label: TAGS_NEW_LABEL,
    value: true,
  },
];
export const TagsOnlyRemaining = [
  {
    label: TAGS_ONLY_REMAINING_LABEL,
    value: true,
  },
];
export const TagsOutOfStock = [
  {
    label: TAGS_OUT_OF_STOCK_LABEL,
    value: true,
  },
];
export const TagsSpecialPrice = [
  {
    label: TAGS_SPECIAL_PRICE_LABEL,
    value: true,
  },
];
export const TagsNoRepeat = [
  {
    label: TAGS_NO_REPEAT_LABEL,
    value: true,
  },
];
export const TagsRetainedStock = [
  {
    label: TAGS_RETAINED_STOCK_LABEL,
    value: true,
  },
];
