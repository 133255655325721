import { sprintf } from 'sprintf-js';

export const formatMessage = (message: string, ...args: string[]) => {
  return sprintf(message, ...args);
};

export const PRIMARY_KEY_EXISTS = '既に登録されています。';
export const ALREADY_UPDATED =
  '他のユーザーによって更新されています。\r\n画面を更新してやり直してください。';
export const ALREADY_DELETE = '既に削除されたデータです。';
export const RECORD_LOCKED = '他のユーザーが更新中です。\r\n時間を置いてやり直してください。';
export const CLOSED_NOT_UPDATE = '締切済みのため、更新できませんでした。';
export const COMFIRMED_NOT_UPDATE = '確定済みのため、更新できませんでした。';
export const SUCCESS_CREATE = '登録が完了しました。';
export const SUCCESS_UPDATE = '更新が完了しました。';
export const SUCCESS_DELETE = '削除が完了しました。';
export const SUCCESS_APPROVAL = '承認が完了しました。';
export const SUCCESS_REMAND = '申請を差し戻しました。';
export const ERROR_CREATE = '登録が失敗しました。もう一度やり直してください。';
export const ERROR_UPDATE = '更新が失敗しました。もう一度やり直してください。';
export const ERROR_DELETE = '削除が失敗しました。もう一度やり直してください。';
export const ERROR_NOT_SELECT_CUSTOMER = '得意先が未選択です。';
export const ERROR_NOT_SELECT_VENDOR = '仕入先が未選択です。';
export const CRITICAL_ERROR = '処理が失敗しました。管理者にご連絡ください。';
export const ERROR_NOT_SELECT = '1件以上選択してください。';
export const NOT_SUCCESS_FETCH =
  'データ取得が成功しませんでした。\r\n画面を更新してやり直してください。';
export const ERROR_WITHDRAWAL_NOT_CONFIRMED = '選択した仕入先に出金未確定の支払情報があります。';
export const ERROR_WITHDRAWAL_CONFIRMED =
  '選択した支払は既に出金確定しています。\r\n確定を取り消してください。';
export const ERROR_REPORT_COUNT_TOO_MANY_ERROR =
  '集計結果が規定件数を超えています。\r\n条件を絞って再集計してください。';
export const STOCK_NOT_ALLOWABLE = '在庫が足りない商品があります。';
export const AUTHORIZATION_ERROR = '操作権限がありません。';
export const DUPLICATE_PERIODS_ERROR = '期間が重複する棚卸基準が存在します。';
export const DUPLICATE_PERIODS_TRUST_INVENTORY_ERROR = '期間が重複する委託棚卸入力が存在します。';
export const CREATE_WITHDRAWAL_ERROR = '掛仕入は支払一覧画面から支払処理をしてください。';
export const PAYMENT_DECISION_ERROR = '最新の支払締があるため、本支払から出金確定を行えません。';
export const SENDORDER_DELETE_ERROR = '入荷情報があるため、発注情報を削除できません。';
export const SENDORDER_APPROVAL_ERROR = '承認に失敗しました。';
export const SENDORDER_REMAND_ERROR = '差し戻しに失敗しました。';
export const SENDORDER_PAYMENT_DELETE_ERROR = '入荷情報があるため、支払確定取消できません。';
export const RECIEVEORDER_DEMAND_DELETE_ERROR =
  '入金情報または出荷情報があるため、請求確定取消できません。';
export const WITHDRAWAL_DELETE_CONFIRMED_ERROR =
  '出金未確定または締切済のため、支払確定取消できません。';
export const PAYMENT_TAX_ADJUST_ERROR =
  '対象の仕入先は掛仕入のため、消費税調整は支払一覧より行ってください。';
export const CLOSING_CANCEL_ERROR = 'この締切は取消できません。';
export const DEPOSIT_ZERO_DEMANDS_ERROR = '入金対象の請求を選択してください。';
export const REQUIRED = '必須項目です。';
export const MUST_EMPTY = '空にして下さい';
export const MIN_VALUE = '%s桁以上で入力してください。';
export const MAX_VALUE = '%s桁以内で入力してください。';
export const FIX_VALUE = '%s桁で入力してください。';
export const NOT_EMAIL = 'E-mailの形式で入力してください。';
export const NOT_NUMBER = '数値で入力してください。';
export const NOT_NUMBER_HYPHEN = '数字とハイフンで入力してください。';
export const NOT_PHONE_NUMBER = '電話番号の形式ではありません。';
export const NOT_HANKAKU = '半角英数字で入力してください。';
export const NOT_HANKAKU_HYPHEN = '半角英数字とハイフンで入力してください。';
export const NOT_YYYYMMDD_HYPHEN = 'YYYY-MM-DD形式で入力してください。';
export const NOT_YYYYMMDD_SLASH = 'YYYY/MM/DD形式で入力してください。';
export const NOT_SELECTED_DATE = '日付を選択して下さい。';
export const INVALID_DATE = '日付を入力してください。';
export const INVALID_INPUT = '不正な値です。';
export const STRING_INPUT = '文字列を入力してください。';
export const NUMBER_INPUT = '数値を入力してください。';
export const UNRECOGNIED_KEYS = 'オブジェクト内に%sはありません。';
export const INVAILD_FUNC_ARGS = '引数が正しくありません。';
export const INVAILD_FUNC_RET_TYPE = '戻り値の型が正しくありません。';
export const INVAILD_INTERSECTION_TYPE = '無効な交差型です。';
export const MAX_HANKAKU_ZENKAKU = '半角%s桁、全角%s桁以内で入力してください。';
export const FIXED_ALPHA_NUM_HANKAKU = '半角英数字%s桁で入力してください。';
export const MAX_ALPHA_NUM_HANKAKU = '半角英数字%s桁以内で入力してください。';
export const NUMBER_RANGE = '%s以上、%s以下で入力してください。';
export const NUMBER_INT = '整数値で入力してください。';
export const NUMBER_DECIMAL_DIGITS = '小数点以下%s桁以内で入力してください。';
export const DATE_FROM_TO = '%sで入力してください。';
export const ARRAY_NOT_ZERO = '１件以上入力してください。';
export const NUMBER_LESS_THAN = '%s未満の値を入力してください。';
export const NUMBER_MORE_THAN = '%sより大きい値を入力してください。';
export const NUMBER_OR_LESS = '%s以下の値を入力してください。';
export const NUMBER_OR_MORE = '%s以上の値を入力してください。';
export const INPUT_NOT_EXIST = '入力された%sは存在しません。';
export const INPUT_JAN_TWO_ATTR = '入力されたJANコードは、２つ以上属性が存在します。';
export const TODO_INPUT_DIALOG = 'ダイアログから選択してください。';
export const INPUT_GRATER_STOCK = '在庫数より少ない数量にしてください';
export const TOTAL_PRICE_TOO_BIGGER = '金額合計が%s桁以内になるよう入力してください。';
export const NO_SELECTED_DEMAND = '請求先を選択してください';
export const BOTH_SAME_WAREHOUSE = '移動元と移動先は別の倉庫で入力してください。';
export const INPUT_EXISTS = '既に入力されています。';
export const DATA_EXISTS = '既に登録されています。';
export const DATA_NOT_EXISTS = '未登録です。';
export const MANY_WAREHOUSE =
  '倉庫が複数存在します。\r\n分納する場合はダイアログから選択してください。';
export const ERROR_CLOSING_DATE = '前回より後の締日を選択してください。';
export const INPUT_PLUS_NUM = '正の値を入力してください。';
export const INPUT_MINUS_NUM = '負の値を入力してください。';
export const PASSWORD_NOT_MATCH = 'パスワードが一致しません。';
export const PASSWORD_REGIX = '少なくとも1文字以上の%sを含めてください。';
export const SELECT_NOT_SAME = '%sと異なる%sを選択してください。';
export const IMPORT_PRODUCT_NOT_UNIQUE = '同一JANコードで異なる値が指定されています。';
export const NUMBER_NOT_ZERO = '0以外の数値を入力してください。';
export const SALES_DEMAND_PRINT_NO_SALES =
  '売上が存在しません。得意先分類が買取先の得意先のみ出力できます。';
export const CUSTOMER_SALESTRADEDVISION_EDIT_ERROR =
  '取引区分を変更する際は残高を0にして変更してください。';
export const RETURN_SHIPMENT_UPDATE_ERROR = '出荷入力で返品伝票の更新削除はできません。';
export const MAX_ORDER_QTY = '発注上限数は%s個以下で入力してください。';
export const JAN_CODE_EXISTS_ERROR = 'JANコードが重複しています。';
/**
 * message(MIN_VALUE, '10')
 * ⇒10桁以上で入力してください。
 */
