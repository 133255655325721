import * as wijmo from '@grapecity/wijmo';
import * as grid from '@grapecity/wijmo.grid';
import { RefObject } from 'react';

import { BaseFlexGridDialog } from './BaseFlexGridDialog';
import { BaseFlexGridWindow } from './BaseFlexGridWindow';

// updatingLayout グリッドで内部レイアウトが更新される前に発生します。
// updatingView 現在のビューを構成する要素の作成/更新をグリッドが開始したときに発生します。
// updatedView グリッドが現在のビューを構成する要素の作成/更新を完了したときに発生します。
// updatedLayout グリッドで内部レイアウトが更新された後に発生します。

export interface SizeProps {
  headerHeight: number;
  vWinHeight: number;
  vWinStartPos: number;
  vWinEndPos: number;
  fgHeight: number;
  stickyScrollPos: number;
}

export type BaseFlexGridProps = {
  children?: React.ReactNode;
  targetViewRef?: RefObject<HTMLElement>;
  fixedHeaderRef?: RefObject<HTMLElement>;
  itemsSource?: any;
  itemValidator?: grid.IItemValidator;
  selectedItems?: any[];
  selectionMode?: grid.SelectionMode;
  frozenColumns?: number;
  frozenRows?: number;
  allowSorting?: grid.AllowSorting;
  beginningEdit?: (s: grid.FlexGrid, e?: grid.CellRangeEventArgs) => void;
  cellEditEnded?: (s: grid.FlexGrid, e?: grid.CellRangeEventArgs) => void;
  cellEditEnding?: (s: grid.FlexGrid, e?: grid.CellEditEndingEventArgs) => void;
  deletedRow?: (s: grid.FlexGrid, e?: grid.CellRangeEventArgs) => void;
  deletingRow?: (s: grid.FlexGrid, e?: grid.CellRangeEventArgs) => void;
  itemsSourceChanged?: (s: grid.FlexGrid, e?: wijmo.EventArgs) => void;
  itemsSourceChanging?: (s: grid.FlexGrid, e?: wijmo.CancelEventArgs) => void;
  loadedRows?: (s: grid.FlexGrid, e?: wijmo.EventArgs) => void;
  loadingRows?: (s: grid.FlexGrid, e?: wijmo.CancelEventArgs) => void;
  lostFocus?: (s: grid.FlexGrid, e?: wijmo.EventArgs) => void;
  pasted?: (s: grid.FlexGrid, e?: grid.CellRangeEventArgs) => void;
  pastedCell?: (s: grid.FlexGrid, e?: grid.CellRangeEventArgs) => void;
  pasting?: (s: grid.FlexGrid, e?: grid.CellRangeEventArgs) => void;
  pastingCell?: (s: grid.FlexGrid, e?: grid.CellRangeEventArgs) => void;
  refreshed?: (s: grid.FlexGrid, e?: wijmo.EventArgs) => void;
  refreshing?: (s: grid.FlexGrid, e?: wijmo.EventArgs) => void;
  rowAdded?: (s: grid.FlexGrid, e?: grid.CellRangeEventArgs) => void;
  rowEditEnded?: (s: grid.FlexGrid, e?: grid.CellRangeEventArgs) => void;
  rowEditEnding?: (s: grid.FlexGrid, e?: grid.CellRangeEventArgs) => void;
  rowEditStarted?: (s: grid.FlexGrid, e?: grid.CellRangeEventArgs) => void;
  rowEditStarting?: (s: grid.FlexGrid, e?: grid.CellRangeEventArgs) => void;
  scrollPositionChanged?: (s: grid.FlexGrid, e?: wijmo.EventArgs) => void;
  selectionChanged?: (s: grid.FlexGrid, e?: grid.CellRangeEventArgs) => void;
  selectionChanging?: (s: grid.FlexGrid, e?: grid.CellRangeEventArgs) => void;
  sortedColumn?: (s: grid.FlexGrid, e?: grid.CellRangeEventArgs) => void;
  sortingColumn?: (s: grid.FlexGrid, e?: grid.CellRangeEventArgs) => void;
  updatedLayout?: (s: grid.FlexGrid, e?: wijmo.EventArgs) => void;
  updatedView?: (s: grid.FlexGrid, e?: wijmo.EventArgs) => void;
  updatingLayout?: (s: grid.FlexGrid, e?: wijmo.CancelEventArgs) => void;
  updatingView?: (s: grid.FlexGrid, e?: wijmo.CancelEventArgs) => void;
  initialized?: (s: grid.FlexGrid) => void;
  formatItem?: (s: grid.FlexGrid, evt: grid.FormatItemEventArgs) => void;
  autoRowHeights?: boolean;
  className?: string;
  groupHeaderFormat?: string;
  copyHeaders?: string;
  allowAddNew?: boolean;
  allowDelete?: boolean;
};

export const BaseFlexGrid = function BaseFlexGridComponent(props: BaseFlexGridProps) {
  return (
    <>
      {props.targetViewRef ? (
        <>
          <BaseFlexGridDialog {...props} targetViewRef={props.targetViewRef}>
            {props.children}
          </BaseFlexGridDialog>
        </>
      ) : (
        <>
          <BaseFlexGridWindow {...props}>{props.children}</BaseFlexGridWindow>
        </>
      )}
    </>
  );
};
