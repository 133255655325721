import { UserTypeEnum } from '@/business_code';

export const acl = [
  {
    // 販売・物流
    role: UserTypeEnum.SALE_STOCK,
    allow: [
      // 得意先台帳、得意先一覧
      'customer',
      // 納入先台帳、納入先一覧
      'deliver',
      // 仕入先台帳、仕入先一覧
      'vendor',
      // 商品台帳、商品一覧
      'product',
      // 得意先別商品価格表
      'customer_price',
      // 構成・セット商品台帳、構成・セット商品一覧
      'component',
      // 受注情報売上原価一括更新
      'sales_unit_price_update',
      // 商品分類
      'product_group',
      // 発注ロットグループ管理
      'send_order_lot',
      // 倉庫台帳、倉庫一覧
      'warehouse',
      // 得意先別予算設定
      'customer_budget',
      // 見積入力、見積一覧
      'estimate',
      // 受注入力、受注一覧
      'receive_order',
      // 出荷入力
      'shipment',
      // 売上入力（委託用）
      'outsourcer_sales',
      // 委託棚卸入力
      'trust_inventory',
      // 売上一覧（委託用）
      'sales_trust',
      // 発注入力、発注一覧
      'send_order',
      // 発注未承認一覧
      'send_order_unapproved_list',
      // 入荷入力
      'arrival',
      // 直営仕入取込/仕掛入力
      'directly_purchase_list',
      // 発注情報仕入単価一括更新
      'purchase_unit_price_update',
      // 発注コレ
      'purchase_kore',
      // 在庫調整入力、在庫調整一覧
      'stock_adjust',
      // 棚卸基準情報登録、棚卸一覧、棚卸入力
      'inventory',
      // 生産入力、生産一覧
      'production',
      // 倉庫移動入力、倉庫移動一覧
      'warehouse_move',
      // 入出庫履歴
      'arrived_delivery_history',
      // 在庫一覧
      'stock_list',
      // 在庫分析(期間指定)
      'stock_analytics',
      // 在庫分析(棚卸)
      'stock_analytics_inventory',
      // 在庫分析(月集計)
      'stock_analytics_by_month',
      // 分納シミュレーション
      'partial_shipment',
      // 受注明細表
      'received_order_detail',
      // 出荷明細表
      'shipment_detail',
      // 売上明細表
      'sales_detail',
      // 売上日報
      'sales_daily',
      // 売上推移表
      'sales_changing_list',
      // 売上分析表
      'sales_analytics',
      // 発注明細表
      'purchase_order_detail',
      // 仕入明細表
      'purchase_detail',
      // 仕入日報
      'purchase_daily',
      // 仕入分析表
      'purchase_analytics',
      // 生産明細表
      'production_detail',
      // 布工房進捗レポート
      'cloth_progress',
      // 社内間取引明細表
      'internal_transaction',
      // 倉庫移動明細
      'warehousemove_detail',

      // 入金入力、入金一覧
      'deposit',
      // 部門成績コレ
      'division_grades',
    ],
  },
  {
    // 経営管理部
    role: UserTypeEnum.MANAGEMENT,
    allow: [
      // 得意先台帳、得意先一覧
      'customer',
      // 納入先台帳、納入先一覧
      'deliver',
      // 仕入先台帳、仕入先一覧
      'vendor',
      // 商品台帳、商品一覧
      'product',
      // 得意先別商品価格表
      'customer_price',
      // 構成・セット商品台帳、構成・セット商品一覧
      'component',
      // 受注情報売上原価一括更新
      'sales_unit_price_update',
      // 商品分類
      'product_group',
      // 発注ロットグループ管理
      'send_order_lot',
      // 倉庫台帳、倉庫一覧
      'warehouse',
      // 得意先別予算設定
      'customer_budget',
      // 見積入力、見積一覧
      'estimate',
      // 受注入力、受注一覧
      'receive_order',
      // 出荷入力
      'shipment',
      // 売上入力（委託用）
      'outsourcer_sales',
      // 委託棚卸入力
      'trust_inventory',
      // 売上一覧（委託用）
      'sales_trust',
      // 発注入力、発注一覧
      'send_order',
      // 発注未承認一覧
      'send_order_unapproved_list',
      // 入荷入力
      'arrival',
      // 直営仕入取込/仕掛入力
      'directly_purchase_list',
      // 発注情報仕入単価一括更新
      'purchase_unit_price_update',
      // 発注コレ
      'purchase_kore',
      // 在庫調整入力、在庫調整一覧
      'stock_adjust',
      // 棚卸基準情報登録、棚卸一覧、棚卸入力
      'inventory',
      // 生産入力、生産一覧
      'production',
      // 倉庫移動入力、倉庫移動一覧
      'warehouse_move',
      // 入出庫履歴
      'arrived_delivery_history',
      // 在庫一覧
      'stock_list',
      // 在庫分析(期間指定)
      'stock_analytics',
      // 在庫分析(棚卸)
      'stock_analytics_inventory',
      // 在庫分析(月集計)
      'stock_analytics_by_month',
      // 分納シミュレーション
      'partial_shipment',
      // 受注明細表
      'received_order_detail',
      // 出荷明細表
      'shipment_detail',
      // 売上明細表
      'sales_detail',
      // 売上日報
      'sales_daily',
      // 売上推移表
      'sales_changing_list',
      // 売上分析表
      'sales_analytics',
      // 発注明細表
      'purchase_order_detail',
      // 仕入明細表
      'purchase_detail',
      // 仕入日報
      'purchase_daily',
      // 仕入分析表
      'purchase_analytics',
      // 生産明細表
      'production_detail',
      // 布工房進捗レポート
      'cloth_progress',
      // 社内間取引明細表
      'internal_transaction',
      // 倉庫移動明細
      'warehousemove_detail',

      // 請求一覧、請求明細
      'demand',
      // 入金入力、入金一覧
      'deposit',
      // 得意先元帳
      'ledger_customer',
      // 売掛残高一覧
      'receivable_balance_list',
      // 支払一覧、支払明細
      'payment',
      // 出金入力、出金一覧
      'withdrawal',
      // 仕入先元帳
      'ledger_vendor',
      // 買掛残高一覧'
      'payable_balance_list',

      // 回収予定表
      'collection_plan_detail',
      // 回収状況一覧表
      'collection_status_list',
      // 入金明細表
      'deposit_detail',
      // 入金日報
      'deposit_daily',
      // 出金明細表
      'withdrawal_detail',
      // 担当者入力、担当者一覧
      'user',
      // 部門入力、部門一覧
      'division',
      // 部門成績コレ
      'division_grades',
    ],
  },
  {
    // 営業
    role: UserTypeEnum.SALES,
    allow: [
      // 商品台帳、商品一覧
      'product',
      // 得意先別商品価格表
      'customer_price',
      // 得意先別予算設定
      'customer_budget',
      // 見積入力、見積一覧
      'estimate',
      // 受注入力、受注一覧
      'receive_order',
      // 出荷入力
      'shipment',
      // 売上入力（委託用）
      'outsourcer_sales',
      // 委託棚卸入力
      'trust_inventory',
      // 売上一覧（委託用）
      'sales_trust',
      // 在庫調整入力、在庫調整一覧
      'stock_adjust',
      // 棚卸基準情報登録、棚卸一覧、棚卸入力
      'inventory',
      // 生産入力、生産一覧
      'production',
      // 倉庫移動入力、倉庫移動一覧
      'warehouse_move',
      // 入出庫履歴
      'arrived_delivery_history',
      // 在庫一覧
      'stock_list',
      // 在庫分析(期間指定)
      'stock_analytics',
      // 在庫分析(棚卸)
      'stock_analytics_inventory',
      // 在庫分析(月集計)
      'stock_analytics_by_month',
      // 分納シミュレーション
      'partial_shipment',
      // 受注明細表
      'received_order_detail',
      // 出荷明細表
      'shipment_detail',
      // 売上明細表
      'sales_detail',
      // 売上日報
      'sales_daily',
      // 売上推移表
      'sales_changing_list',
      // 売上分析表
      'sales_analytics',
      // 発注明細表
      'purchase_order_detail',
      // 仕入明細表
      'purchase_detail',
      // 仕入日報
      'purchase_daily',
      // 仕入分析表
      'purchase_analytics',
      // 生産明細表
      'production_detail',
      // 布工房進捗レポート
      'cloth_progress',
      // 社内間取引明細表
      'internal_transaction',
      // 倉庫移動明細
      'warehousemove_detail',
      // 部門成績コレ
      'division_grades',
    ],
    disallow: [
      // 在庫調整入力
      'stock_adjust/new',
      // 棚卸基準情報登録
      'inventory/new',
      // 生産入力
      'production/new',
      // 倉庫移動入力
      'warehouse_move/new',
    ],
  },
  {
    // 商品企画
    role: UserTypeEnum.PLANNER,
    allow: [
      // 商品台帳、商品一覧
      'product',
      // 見積入力、見積一覧
      'estimate',
      // 受注入力、受注一覧
      'receive_order',
      // 受注明細表
      'received_order_detail',
      // 出荷明細表
      'shipment_detail',
      // 売上明細表
      'sales_detail',
      // 売上日報
      'sales_daily',
      // 売上推移表
      'sales_changing_list',
      // 売上分析表
      'sales_analytics',
      // 発注明細表
      'purchase_order_detail',
      // 仕入明細表
      'purchase_detail',
      // 仕入日報
      'purchase_daily',
      // 仕入分析表
      'purchase_analytics',
    ],
  },
  {
    // デザイナ
    role: UserTypeEnum.DESIGNER,
    allow: [
      // 商品台帳、商品一覧
      'product',
      // 出荷明細表
      'shipment_detail',
      // 売上明細表
      'sales_detail',
      // 売上分析表
      'sales_analytics',
      // 発注明細表
      'purchase_order_detail',
      // 仕入明細表
      'purchase_detail',
      // 仕入日報
      'purchase_daily',
      // 仕入分析表
      'purchase_analytics',
    ],
  },
  {
    // 布工房
    role: UserTypeEnum.CLOTH,
    allow: [
      // 商品台帳、商品一覧
      'product',
      // 受注入力、受注一覧
      'receive_order',
      // 出荷入力
      'shipment',
      // 発注入力、発注一覧
      'send_order',
      // 発注未承認一覧
      'send_order_unapproved_list',
      // 入荷入力
      'arrival',
      // 直営仕入取込/仕掛入力
      'directly_purchase_list',
      // 布工房進捗レポート
      'cloth_progress',
    ],
  },
  {
    // 直営店
    role: UserTypeEnum.DIRECT,
    allow: [
      // 受注入力、受注一覧
      'receive_order',
    ],
  },
];
