import { Navigate, Route, Routes } from 'react-router-dom';

import { DivisionGradesList } from './DivisionGradesLists';

export const DivisionGradesRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<DivisionGradesList />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
};
