import * as wjCore from '@grapecity/wijmo';
import * as wjgridCore from '@grapecity/wijmo.grid';
import _ from 'lodash';
import React, { RefObject } from 'react';
import { FieldError } from 'react-hook-form';

import { diffObject2 } from '@/utils/diffObject';

import { DetailFlexGrid } from '../FlexGrid';

export type DiffDetectionItemsSource = {
  id: string;
  frontNumbering?: number;
  [key: string]: any;
};

type DiffDetectionCollectionViewProps = {
  itemsSource: DiffDetectionItemsSource[];
  fixedHeaderRef?: RefObject<HTMLElement>;
  collectionViewConfigs: {
    newItemCreator: () => DiffDetectionItemsSource | undefined;
  };
  initialized: (s: wjgridCore.FlexGrid) => void;
  onChange: (values: any[]) => void;
  error: FieldError | undefined;
  name: string;
  allowMerging?: string;
  disableNumbering?: boolean;
};

type DiffDetectionCollectionViewStates = {
  collectionView: wjCore.CollectionView;
  flexgrid: wjgridCore.FlexGrid | undefined;
};

export class DiffDetectionCollectionView extends React.Component<
  DiffDetectionCollectionViewProps,
  DiffDetectionCollectionViewStates
> {
  tooltip: wjCore.Tooltip;
  constructor(props: DiffDetectionCollectionViewProps) {
    super(props);
    this.state = {
      collectionView: this.updateCollectionView(props.itemsSource),
      flexgrid: undefined,
    };
    this.tooltip = new wjCore.Tooltip({
      cssClass: 'wj-custom-alert-tooltip',
    });
  }
  componentDidUpdate(prevProps: DiffDetectionCollectionViewProps) {
    // prevProps: DiffDetectionCollectionViewProps
    // if (!_.isEqual(prevProps.error, this.props.error)) {
    //   if (this.state.flexgrid) this.state.flexgrid.invalidate();
    // }
    if (!_.isEqual(prevProps.itemsSource, this.props.itemsSource)) {
      this.setState((state: DiffDetectionCollectionViewStates) => {
        state.collectionView = this.updateCollectionView(this.props.itemsSource);
        return state;
      });
      this.props.onChange(diffObject2(this.props.itemsSource, this.props.itemsSource));
    }
    if (!_.isEqual(prevProps.error, this.props.error)) {
      if (this.props.error) {
        this.state.flexgrid?.refresh();
      }
    }
    if (!this.props.error) {
      this.tooltip.dispose();
    }
  }
  updateCollectionView(values: DiffDetectionItemsSource[]) {
    return new wjCore.CollectionView(_.cloneDeep(values), {
      ...this.props.collectionViewConfigs,
    });
  }
  formatItem(s: wjgridCore.FlexGrid, e: any) {
    if (!this.props.error) return;
    if (e.panel == s.cells) {
      const errs = this.props.error as any;
      let errState = false;
      const err = errs[e.row];
      const binding = s.columns[e.col].binding;
      if (err && binding && err[binding]) {
        this.tooltip.setTooltip(e.cell, err[binding]['message']);
        errState = true;
      }
      wjCore.toggleClass(e.cell, 'wj-state-invalid', errState);
    }
  }
  changeDetection(s: wjgridCore.FlexGrid) {
    this.props.onChange(diffObject2(this.props.itemsSource, s.itemsSource.items));
  }
  reNumbering(s: wjgridCore.FlexGrid) {
    const itemsSource: DiffDetectionItemsSource[] = s.itemsSource.items;
    itemsSource.forEach((item, index) => {
      item.frontNumbering = index + 1;
    });
  }
  deletedRow(s: wjgridCore.FlexGrid) {
    if (!this.props.disableNumbering) this.reNumbering(s);
    this.changeDetection(s);
  }
  initialized(s: wjgridCore.FlexGrid) {
    this.setState({
      flexgrid: s,
    });
    s.cellEditEnded.addHandler(this.changeDetection.bind(this));
    s.pasted.addHandler(this.changeDetection.bind(this));
    s.deletedRow.addHandler(this.deletedRow.bind(this));
    s.formatItem.addHandler(this.formatItem.bind(this));
    this.props.initialized(s);
  }
  render() {
    const message = _.get(this.props.error, 'message');
    return (
      <>
        {message && (
          <div className="alert alert-danger" role="alert">
            {message}
          </div>
        )}
        <DetailFlexGrid
          itemsSource={this.state.collectionView}
          initialized={this.initialized.bind(this)}
          fixedHeaderRef={this.props.fixedHeaderRef}
          allowMerging={this.props.allowMerging ?? 'None'}
        >
          {this.props.children}
        </DetailFlexGrid>
      </>
    );
  }
}
