import * as grid from '@grapecity/wijmo.grid';
import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { Control, Controller } from 'react-hook-form';

import { diffObject2 } from '@/utils/diffObject';

import { DetailFlexGrid } from '../FlexGrid';
import { BaseFlexGridProps } from '../FlexGrid/BaseFlexGrid';

type EditableContentsProps = BaseFlexGridProps & {
  control: Control<any> | undefined;
  name: string;
  notAllowAddNew?: boolean;
  notAllowDelete?: boolean;
  allowMerging?: string;
};

export const EditableContents = ({
  control,
  name,
  children,
  itemsSource,
  initialized,
  itemsSourceChanged,
  notAllowAddNew,
  notAllowDelete,
  allowMerging,
  allowSorting,
  frozenColumns,
}: EditableContentsProps) => {
  const beforeSourceRef = useRef([]);

  useEffect(() => {
    beforeSourceRef.current = _.cloneDeep(itemsSource);
  }, [itemsSource]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <>
          {_.get(error, 'message') && (
            <div className="alert alert-danger" role="alert">
              {_.get(error, 'message')}
            </div>
          )}
          <DetailFlexGrid
            itemsSource={itemsSource}
            error={error}
            cellEditEnded={(s: grid.FlexGrid) => {
              const diff = diffObject2(beforeSourceRef.current, s.itemsSource);
              onChange(diff);
            }}
            pasted={(s: grid.FlexGrid) => {
              const diff = diffObject2(beforeSourceRef.current, s.itemsSource);
              onChange(diff);
            }}
            deletedRow={(s: grid.FlexGrid) => {
              const diff = diffObject2(beforeSourceRef.current, s.itemsSource);
              onChange(diff);
            }}
            initialized={initialized}
            itemsSourceChanged={itemsSourceChanged}
            notAllowAddNew={notAllowAddNew ?? false}
            notAllowDelete={notAllowDelete ?? false}
            allowMerging={allowMerging ?? 'None'}
            allowSorting={allowSorting ?? grid.AllowSorting.SingleColumn}
            frozenColumns={frozenColumns}
          >
            {children}
          </DetailFlexGrid>
        </>
      )}
    />
  );
};
