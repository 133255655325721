import { Globalize } from '@grapecity/wijmo';
import { FlexGrid } from '@grapecity/wijmo.grid';
import { FlexGridXlsxConverter } from '@grapecity/wijmo.grid.xlsx';
import _ from 'lodash';
import { forwardRef, useRef, useImperativeHandle, useState, useEffect } from 'react';

import { Button } from '@/components/Elements';

import { CustomGridColumn, ListFlexGrid } from '../FlexGrid';

import { ListContentsHandler, ListContentsProps } from '.';

export const SelectableListContents = forwardRef<ListContentsHandler, ListContentsProps>(
  (props, ref) => {
    // State変数
    const [selectedCnt, setSelectedCnt] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [exportIsReady, setExportIsReady] = useState(false);

    // インスタンス変数
    const _flexGrid = useRef<FlexGrid>();
    const _totalCount = useRef(0);
    const _selectedCntRef = useRef<number>(0);
    const _defaultSelectType = useRef<boolean>(props?.defaultSelected ? true : false);
    const _selectList = useRef<string[]>([]);
    const _defaultChangeAllSelectedButtonDisplay = useRef<boolean>(
      props?.defaultChangeAllSelectedButtonDisplay ?? true
    );

    // 総件数表示イベント（初期表示遅延処理）
    useEffect(() => {
      if (typeof props.totalCount === 'number') {
        setTotalCount(props.totalCount);
        _totalCount.current = props.totalCount;
        if (_defaultSelectType) setSelectedCnt(props.totalCount);
        _selectedCntRef.current = props.totalCount;
      }
      _selectList.current = [];
      changeSelectedCnt();
    }, [props.itemsSource, props.totalCount]);

    useEffect(() => {
      if (props.totalCount && props.itemsSource.length >= props.totalCount && exportIsReady) {
        if (_flexGrid?.current)
          FlexGridXlsxConverter.saveAsync(
            _flexGrid.current,
            { includeColumnHeaders: true, includeCellStyles: false },
            'export.xlsx'
          );
        setExportIsReady(false);
      }
    }, [props.itemsSource, exportIsReady, props.totalCount]);

    // 外向き用の関数
    const exportData = () => {
      requestAllPage().then(() => {
        setExportIsReady(true);
      });
    };
    const refresh = () => {
      if (_flexGrid?.current?.collectionView?.refresh) _flexGrid.current.collectionView.refresh();
    };
    const getSelectData = () => {
      const grid = _flexGrid.current;
      if (grid) {
        const res: any[] = [];
        grid.itemsSource.map((data: any) => {
          if (data.selected) res.push(data);
        });
        return res;
      } else {
        return [];
      }
    };
    const getSelectDataKey = (key: string): string[] | 'ALL' => {
      const grid = _flexGrid.current;
      if (grid) {
        const keys: string[] = [];
        grid.itemsSource.map((data: any) => {
          if (data.selected) keys.push(_.get(data, key));
        });
        let res: string[] | 'ALL' = keys;
        if (grid.itemsSource.length === res.length) res = 'ALL';
        return res;
      } else {
        return [];
      }
    };
    const getAllData = () => {
      const grid = _flexGrid.current;
      if (grid) {
        const res: any[] = [];
        grid.itemsSource.map((data: any) => {
          res.push(data);
        });
        return res;
      } else {
        return [];
      }
    };
    useImperativeHandle(ref, () => {
      return {
        exportData() {
          exportData();
        },
        refresh() {
          refresh();
        },
        getSelectData() {
          return getSelectData();
        },
        getSelectDataKey(key) {
          return getSelectDataKey(key);
        },
        getAllData() {
          return getAllData();
        },
      };
    });

    // 追加データ取得用のトリガー
    let maxIdx = 0;
    const requestNext = (s: FlexGrid) => {
      if (props.isFetched && props.onRequestNext) {
        if (s.viewRange.bottomRow >= s.rows.length - 1) {
          const index = s.viewRange.bottomRow;
          if (index > maxIdx) {
            maxIdx = index;
            props.onRequestNext();
          }
        }
      }
    };
    const requestAllPage = () => {
      const recursive_request = () => {
        return new Promise<void>((resolve, reject) => {
          if (props.onRequestNext) {
            props
              .onRequestNext()
              .then((res) => {
                if (res.hasNextPage)
                  recursive_request()
                    .then(() => {
                      resolve();
                    })
                    .catch(() => {
                      reject();
                    });
                else resolve();
              })
              .catch(() => {
                reject();
              });
          } else {
            resolve();
          }
        });
      };
      return recursive_request();
    };

    // デフォルト選択値変更
    const changeDefaultSelected = (bool: boolean) => {
      _defaultSelectType.current = bool;
    };

    // すべて選択、選択解除イベント
    const changeAllSelected = (bool: boolean) => {
      changeDefaultSelected(bool);
      const s = _flexGrid?.current;
      if (s) {
        window.requestAnimationFrame(() => {
          s.itemsSource.map((v: any) => (v['selected'] = _defaultSelectType.current));
          refresh();
        });
      }
      resetSelectList();
    };

    // 選択配列追加
    const addSelectList = (id: string) => {
      const oldL = _selectList.current;
      oldL.push(id);
      const newL = Array.from(new Set(oldL));
      _selectList.current = newL;

      changeSelectedCnt();
    };

    // 選択配列削除
    const removeSelectList = (id: string) => {
      _selectList.current.some(function (v, i) {
        if (v == id) _selectList.current.splice(i, 1);
      });

      changeSelectedCnt();
    };

    // 選択配列初期化
    const resetSelectList = () => {
      _selectList.current = [];
      changeSelectedCnt();
    };

    // 選択済み件数の更新
    const changeSelectedCnt = () => {
      const len = _selectList.current.length;
      if (_defaultSelectType.current === true) {
        setSelectedCnt(_totalCount.current - len);
      } else {
        setSelectedCnt(len);
      }
    };

    // 選択状態更新
    const updateSelectedState = (itemsSource: any[]) => {
      window.requestAnimationFrame(() => {
        itemsSource.map((v: any) => {
          if (_selectList.current.includes(v['id'])) {
            v['selected'] = !_defaultSelectType.current;
          } else {
            v['selected'] = _defaultSelectType.current;
          }
        });
        refresh();
      });
    };

    // FlexGridのイベントを拡張
    const flexGridEvent = {
      initialized: (s: FlexGrid): void => {
        _flexGrid.current = s;
        s.cellEditEnding.addHandler((s: any, e: any) => {
          const col = s.columns[e.col];
          if (col.binding == 'selected') {
            const checkElem = s.activeEditor;
            if (checkElem) {
              if (checkElem.checked && _defaultSelectType.current) {
                removeSelectList(e.getRow().dataItem.id);
              } else if (!(checkElem.checked || _defaultSelectType.current)) {
                removeSelectList(e.getRow().dataItem.id);
              } else {
                addSelectList(e.getRow().dataItem.id);
              }
            }
          }
        });
        props.initialized?.call(null, s);
      },
      itemsSourceChanged: (s: FlexGrid): void => {
        if (s?.itemsSource) updateSelectedState(s.itemsSource);
      },
      scrollPositionChanged: (s: FlexGrid) => {
        requestNext(s);
      },
    };
    return (
      <>
        <div className="fk__btn-group mb-3">
          <>
            {_defaultChangeAllSelectedButtonDisplay.current && (
              <>
                <Button variant="secondary" onClick={() => changeAllSelected(true)}>
                  すべて選択
                </Button>
                <Button variant="secondary" onClick={() => changeAllSelected(false)}>
                  選択解除
                </Button>
              </>
            )}
            <div className="text-end fk__btn-group__float-right">
              {`選択済み ${Globalize.format(selectedCnt, 'n')}件 ／ 検索結果 ${Globalize.format(
                totalCount,
                'n'
              )}件`}
            </div>
          </>
        </div>
        <div>
          <ListFlexGrid
            {...props}
            {...flexGridEvent}
            itemsSource={props.itemsSource}
            linkKey={props?.linkKey}
            frozenColumns={props?.frozenColumns}
          >
            <CustomGridColumn
              binding="selected"
              header={props.selectHeader ?? ' '}
              dataType="Boolean"
              width={50}
            />
            {props.children}
          </ListFlexGrid>
        </div>
      </>
    );
  }
);

SelectableListContents.displayName = 'SelectableListContents';
