import * as wjcGrid from '@grapecity/wijmo.grid';
import React, { RefObject } from 'react';
import { Control, Controller } from 'react-hook-form';

import {
  DiffDetectionCollectionView,
  DiffDetectionItemsSource,
} from './DiffDetectionCollectionView';

type EditableContentsCollectionViewProps = {
  control: Control<any> | undefined;
  name: string;
  itemsSource: DiffDetectionItemsSource[];
  fixedHeaderRef?: RefObject<HTMLElement>;
  initialized: (s: wjcGrid.FlexGrid) => void;
  collectionViewConfigs: {
    newItemCreator: () => DiffDetectionItemsSource | undefined;
  };
  allowMerging?: string;
  disableNumbering?: boolean;
};

export class EditableContentsCollectionView extends React.Component<EditableContentsCollectionViewProps> {
  render() {
    return (
      <>
        <Controller
          control={this.props.control}
          name={this.props.name}
          render={({ field: { onChange }, fieldState: { error } }) => (
            <>
              <DiffDetectionCollectionView
                name={this.props.name}
                itemsSource={this.props.itemsSource}
                initialized={this.props.initialized}
                collectionViewConfigs={this.props.collectionViewConfigs}
                onChange={onChange}
                error={error}
                fixedHeaderRef={this.props.fixedHeaderRef}
                allowMerging={this.props.allowMerging}
                disableNumbering={this.props.disableNumbering}
              >
                {this.props.children}
              </DiffDetectionCollectionView>
            </>
          )}
        />
      </>
    );
  }
}
