export enum ImportTypeProductListEnum {
  CREATE = '新規',
  UPDATE = '更新',
  DELETE = '商品削除',
  DELETEPRICE = '価格削除',
}
export const ImportTypeProductList = [
  { label: ImportTypeProductListEnum.CREATE, value: '0' },
  { label: ImportTypeProductListEnum.UPDATE, value: '1' },
  { label: ImportTypeProductListEnum.DELETE, value: '2' },
  { label: ImportTypeProductListEnum.DELETEPRICE, value: '3' },
];
