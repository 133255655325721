import { MenuGroupParam } from '../types';

export const menus: MenuGroupParam[] = [
  {
    groupName: '台帳',
    menus: [
      {
        title: '得意先台帳',
        to: '/app/customer/new',
      },
      {
        title: '得意先一覧',
        to: '/app/customer/',
        end: true,
      },
      {
        title: '得意先別商品価格表 ',
        to: '/app/customer_price/',
      },
      {
        title: '得意先別予算設定',
        to: '/app/customer_budget/',
      },
      {
        title: '納入先台帳',
        to: '/app/deliver/new',
      },
      {
        title: '納入先一覧',
        to: '/app/deliver/',
        end: true,
      },
      {
        title: '仕入先台帳',
        to: '/app/vendor/new',
      },
      {
        title: '仕入先一覧',
        to: '/app/vendor/',
        end: true,
      },
      {
        title: '商品台帳',
        to: '/app/product/new',
      },
      {
        title: '商品一覧',
        to: '/app/product/',
        end: true,
      },
      {
        title: '構成・セット商品台帳',
        to: '/app/component/new',
      },
      {
        title: '構成・セット商品一覧',
        to: '/app/component/',
        end: true,
      },
      {
        title: '商品分類',
        to: '/app/product_group/',
      },
      {
        title: '発注ロットグループ管理',
        to: '/app/send_order_lot/',
      },
      {
        title: '倉庫台帳',
        to: '/app/warehouse/new',
      },
      {
        title: '倉庫一覧',
        to: '/app/warehouse/',
        end: true,
      },
      {
        title: 'ログインユーザー入力',
        to: '/app/user/new',
      },
      {
        title: 'ログインユーザー一覧',
        to: '/app/user/',
        end: true,
      },
      {
        title: '部門入力',
        to: '/app/division/new',
      },
      {
        title: '部門一覧',
        to: '/app/division/',
        end: true,
      },
    ],
  },
  {
    groupName: '受注/出荷',
    menus: [
      {
        title: '見積入力',
        to: '/app/estimate/new',
      },
      {
        title: '見積一覧',
        to: '/app/estimate/',
        end: true,
      },
      {
        title: '受注入力',
        to: '/app/receive_order/new',
      },
      {
        title: '受注一覧',
        to: '/app/receive_order/',
        end: true,
      },
      {
        title: '受注情報売上原価一括更新',
        to: '/app/sales_unit_price_update',
      },
      {
        title: '売上入力（委託用）',
        to: '/app/outsourcer_sales/new',
      },
      {
        title: '売上一覧（委託用）',
        to: '/app/sales_trust/',
      },
      {
        title: '委託棚卸入力',
        to: '/app/trust_inventory/new',
      },
    ],
  },
  {
    groupName: '発注/入荷',
    menus: [
      {
        title: '発注入力',
        to: '/app/send_order/new',
      },
      {
        title: '発注一覧',
        to: '/app/send_order/',
        end: true,
      },
      {
        title: '発注情報仕入単価一括更新',
        to: '/app/purchase_unit_price_update',
      },
      {
        title: '直営仕入取込/仕掛入力',
        to: '/app/directly_purchase_list/purchase',
      },
      {
        disabled: true,
        title: '分納シミュレーション',
        to: '/app/partial_shipment/by_product',
      },
      {
        title: '発注未承認一覧',
        to: '/app/send_order_unapproved_list',
      },
      {
        title: '発注コレ',
        to: '/app/purchase_kore',
      },
    ],
  },
  {
    groupName: '請求/入金',
    menus: [
      {
        title: '請求一覧',
        to: '/app/demand/',
        end: true,
      },
      {
        title: '請求明細',
        to: '/app/demand/new',
      },
      {
        title: '入金入力',
        to: '/app/deposit/new',
      },
      {
        title: '入金一覧',
        to: '/app/deposit/',
        end: true,
      },
      {
        title: '得意先元帳',
        to: '/app/ledger_customer',
      },
      {
        title: '売掛残高一覧',
        to: '/app/receivable_balance_list',
      },
    ],
  },
  {
    groupName: '支払/出金',
    menus: [
      {
        title: '支払一覧',
        to: '/app/payment/',
        end: true,
      },
      {
        title: '支払明細',
        to: '/app/payment/new',
      },
      {
        title: '出金入力',
        to: '/app/withdrawal/new',
      },
      {
        title: '出金一覧',
        to: '/app/withdrawal/',
        end: true,
      },
      {
        title: '仕入先元帳',
        to: '/app/ledger_vendor',
      },
      {
        title: '買掛残高一覧',
        to: '/app/payable_balance_list',
      },
    ],
  },
  {
    groupName: '在庫',
    menus: [
      {
        title: '在庫調整入力',
        to: '/app/stock_adjust/new',
      },
      {
        title: '在庫調整一覧',
        to: '/app/stock_adjust',
        end: true,
      },
      {
        title: '棚卸基準登録',
        to: '/app/inventory/new',
      },
      {
        title: '棚卸一覧',
        to: '/app/inventory',
        end: true,
      },
      {
        title: '生産入力',
        to: '/app/production/new',
      },
      {
        title: '生産一覧',
        to: '/app/production/',
        end: true,
      },
      {
        title: '倉庫移動入力',
        to: '/app/warehouse_move/new',
      },
      {
        title: '倉庫移動一覧',
        to: '/app/warehouse_move',
        end: true,
      },
      {
        title: '在庫一覧',
        to: '/app/stock_list',
      },
      {
        title: '在庫分析(期間集計)',
        to: '/app/stock_analytics',
        end: true,
      },
      {
        title: '在庫分析(棚卸)',
        to: '/app/stock_analytics_inventory',
        end: true,
      },
      {
        title: '在庫分析(月集計)',
        to: '/app/stock_analytics_by_month',
        end: true,
      },
    ],
  },
  {
    groupName: 'レポート',
    menus: [
      {
        title: '受注明細表',
        to: '/app/received_order_detail',
      },
      {
        title: '出荷明細表',
        to: '/app/shipment_detail',
      },
      {
        title: '売上明細表',
        to: '/app/sales_detail',
      },
      {
        title: '売上日報',
        to: '/app/sales_daily',
      },
      {
        title: '売上推移表',
        to: '/app/sales_changing_list',
      },
      {
        title: '売上分析表',
        to: '/app/sales_analytics',
      },
      {
        title: '回収予定表',
        to: '/app/collection_plan_detail',
      },
      {
        title: '回収状況一覧表',
        to: '/app/collection_status_list',
      },
      {
        title: '入金明細表',
        to: '/app/deposit_detail',
      },
      {
        title: '入金日報',
        to: '/app/deposit_daily',
      },
      {
        title: '発注明細表',
        to: '/app/purchase_order_detail',
      },
      {
        title: '仕入明細表',
        to: '/app/purchase_detail',
      },
      {
        title: '仕入日報',
        to: '/app/purchase_daily',
      },
      {
        title: '仕入分析表',
        to: '/app/purchase_analytics',
      },
      {
        title: '出金明細表',
        to: '/app/withdrawal_detail',
      },
      {
        title: '生産明細表',
        to: '/app/production_detail',
      },
      {
        title: '入出庫履歴',
        to: '/app/arrived_delivery_history',
      },
      {
        title: '布工房進捗レポート',
        to: '/app/cloth_progress',
      },
      {
        title: '社内間取引明細表',
        to: '/app/internal_transaction',
      },
      {
        title: '倉庫移動明細表',
        to: '/app/warehousemove_detail',
      },
      {
        title: '部門成績コレ',
        to: '/app/division_grades',
      },
    ],
  },
  // {
  //   disabled: true,
  //   groupName: 'その他',
  //   menus: [
  //     {
  //       title: 'システム管理',
  //       to: '/app/system_management',
  //     },
  //   ],
  // },
];
