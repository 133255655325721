export enum BillSendDivisionEnum {
  NOT_SEND = '0',
  ELECTRONIC = '1',
  MAIL = '2',
  WEB_SYSTEM = '3',
  FAX = '4',
}

export const BillSendDivision = [
  { label: '請求書発行なし', value: BillSendDivisionEnum.NOT_SEND },
  { label: '郵送', value: BillSendDivisionEnum.MAIL },
  { label: 'FAX', value: BillSendDivisionEnum.FAX },
  { label: 'メール', value: BillSendDivisionEnum.ELECTRONIC },
  { label: 'Webシステム', value: BillSendDivisionEnum.WEB_SYSTEM },
];
