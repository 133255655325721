import { Globalize } from '@grapecity/wijmo';
import { ICellTemplateContext } from '@grapecity/wijmo.grid';
import { FlexGridCellTemplate } from '@grapecity/wijmo.react.grid';
import clsx from 'clsx';
import _ from 'lodash';
import { Component } from 'react';

import { CustomGridColumn } from '..';

type CurrancyColumnProps = {
  header: string;
  binding: string;
  symbol?: 'YEN' | 'USD' | 'NONE';
  scale?: number;
  [key: string]: any;
};

export class CurrancyColumn extends Component<CurrancyColumnProps> {
  template(context: ICellTemplateContext) {
    const value = _.get(context, `item.${this.props.binding}`);
    if (value === undefined || value === '' || value === null) return <></>;
    const cnvValue = Number(value);
    const symbol = this.getSymbol(this.props.symbol);
    const scale = this.props.scale ? this.props.scale : 4;
    if (!isNaN(cnvValue)) {
      const sign = cnvValue < 0 ? 'negative' : 'positive';
      return (
        <span className={clsx(sign)}>{`${symbol}${Globalize.formatNumber(
          cnvValue,
          `G${scale}`
        )}`}</span>
      );
    } else {
      return <></>;
    }
  }

  getSymbol(symbol: 'YEN' | 'USD' | 'NONE' | undefined) {
    if (symbol === 'NONE') return '';
    if (symbol === 'USD') return '$';
    return '¥';
  }

  render() {
    return (
      <CustomGridColumn {...this.props}>
        <FlexGridCellTemplate cellType="Cell" template={this.template.bind(this)} />
      </CustomGridColumn>
    );
  }
}
