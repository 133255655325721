import { Globalize } from '@grapecity/wijmo';
import { FlexGrid } from '@grapecity/wijmo.grid';
import { FlexGridXlsxConverter } from '@grapecity/wijmo.grid.xlsx';
import { forwardRef, useRef, useImperativeHandle, useState, useEffect } from 'react';

import { ListFlexGrid } from '../FlexGrid';

import { ListContentsHandler, ListContentsProps } from './ListContents';

export const SumListContents = forwardRef<ListContentsHandler, ListContentsProps>((props, ref) => {
  // State変数
  const [totalCount, setTotalCount] = useState(0);

  // インスタンス変数
  const _flexGrid = useRef<FlexGrid>();
  const _totalCount = useRef(0);

  // 総件数表示イベント（初期表示遅延処理）
  useEffect(() => {
    if (props.totalCount) {
      setTotalCount(props.totalCount);
      _totalCount.current = props.totalCount;
    }
  }, [props.itemsSource, props.totalCount]);

  // 外向き用の関数
  const exportData = () => {
    if (_flexGrid?.current)
      FlexGridXlsxConverter.saveAsync(
        _flexGrid.current,
        { includeColumnHeaders: true, includeCellStyles: false },
        'test.xlsx'
      );
  };
  const refresh = () => {
    if (_flexGrid?.current) _flexGrid.current.collectionView.refresh();
  };
  const getSelectData = () => {
    const grid = _flexGrid.current;
    if (grid) {
      return [grid.itemsSource[grid.selection._row]];
    } else {
      return [];
    }
  };
  useImperativeHandle(ref, () => {
    return {
      exportData() {
        exportData();
      },
      refresh() {
        refresh();
      },
      getSelectData() {
        return getSelectData();
      },
    };
  });

  // 追加データ取得用のトリガー
  let maxIdx = 0;
  const requestNext = (s: FlexGrid) => {
    if (props.isFetched && props.onRequestNext) {
      if (s.viewRange.bottomRow >= s.rows.length - 1) {
        const index = s.viewRange.bottomRow;
        if (index > maxIdx) {
          maxIdx = index;
          props.onRequestNext();
        }
      }
    }
  };

  // FlexGridのイベントを拡張
  const flexGridEvent = {
    initialized: (s: FlexGrid): void => {
      _flexGrid.current = s;
    },
    scrollPositionChanged: (s: FlexGrid) => {
      requestNext(s);
    },
  };
  return (
    <>
      <div className="fk__btn-group mb-3">
        <>
          <div className="text-end fk__btn-group__float-right">
            {`検索結果 ${Globalize.format(totalCount, 'n')}件`}
          </div>
        </>
      </div>
      <div>
        <ListFlexGrid
          {...props}
          {...flexGridEvent}
          itemsSource={props.itemsSource}
          linkKey={props?.linkKey}
          frozenColumns={props?.frozenColumns}
        >
          {props.children}
        </ListFlexGrid>
      </div>
    </>
  );
});

SumListContents.displayName = 'SumListContents';
