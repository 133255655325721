import { DataMap } from '@grapecity/wijmo.grid';
import { FlexGridColumn } from '@grapecity/wijmo.react.grid';

type CustomGridColumnProps = {
  template?: any;
  children?: any;
  name?: any;
  dataMap?: DataMap;
  dataType?: any;
  binding?: any;
  sortMemberPath?: any;
  format?: any;
  cellTemplate?: any;
  header?: any;
  width?: any;
  maxLength?: any;
  minWidth?: any;
  maxWidth?: any;
  align?: any;
  allowDragging?: any;
  allowSorting?: any;
  allowResizing?: any;
  allowMerging?: any;
  aggregate?: any;
  isReadOnly?: any;
  cssClass?: any;
  cssClassAll?: any;
  isContentHtml?: any;
  isSelected?: any;
  visible?: any;
  wordWrap?: any;
  multiLine?: any;
  mask?: any;
  inputType?: any;
  isRequired?: any;
  showDropDown?: any;
  dataMapEditor?: any;
  dropDownCssClass?: any;
  quickAutoSize?: any;
  editor?: any;
  initialized?: any;
  [key: string]: any;
};

export const CustomGridColumn = (props: CustomGridColumnProps) => {
  return <FlexGridColumn {...props}>{props.children}</FlexGridColumn>;
};
