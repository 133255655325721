import clsx from 'clsx';
import * as React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { compareValue } from '@/utils/compareValue';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type Option = {
  label: React.ReactNode;
  value: string | number;
};

type RadioFieldProps = FieldWrapperPassThroughProps & {
  options: Option[];
  className?: string;
  defaultValue?: string | number;
  registration: Partial<UseFormRegisterReturn>;
  disabled?: boolean;
};

export const RadioField = (props: RadioFieldProps) => {
  const { label, options, error, className, defaultValue, registration, disabled } = props;
  return (
    <FieldWrapper label={label} error={error}>
      <div className="row row-cols-auto">
        {options.map(({ label, value }) => (
          <div className="col" key={value}>
            <div className="form-check fk__alt-form-control fk__alt-form-control__label">
              <input
                type="radio"
                className={clsx('form-check-input', className)}
                value={value}
                defaultChecked={defaultValue ? compareValue(defaultValue, value) : undefined}
                {...registration}
                id={`${registration.name}-${value}`}
                disabled={disabled}
              />
              <label className="form-check-label" htmlFor={`${registration.name}-${value}`}>
                {label}
              </label>
            </div>
          </div>
        ))}
      </div>
    </FieldWrapper>
  );
};
