import { Title } from '@/components/Layout';

import { FetchDivisionGradeLists } from '../components/DivisionGrades';

export const DivisionGradesList = () => {
  return (
    <>
      <Title>部門成績コレ</Title>
      <FetchDivisionGradeLists />
    </>
  );
};
