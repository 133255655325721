export enum SendOrderApprovalStatusEnum {
  /** なし */ NONE = '0',
  /** 未承認 */ WAITING = '1',
  /** 承認済み */ APPROVED = '2',
  /** 差し戻し */ REJECTED = '3',
}

export const SendOrderApprovalStatus = [
  { label: 'なし', value: SendOrderApprovalStatusEnum.NONE },
  { label: '未承認', value: SendOrderApprovalStatusEnum.WAITING },
  { label: '承認済み', value: SendOrderApprovalStatusEnum.APPROVED },
  { label: '差し戻し', value: SendOrderApprovalStatusEnum.REJECTED },
];
