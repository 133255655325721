/* eslint-disable @typescript-eslint/no-unused-vars */
import * as wijmo from '@grapecity/wijmo';
import * as grid from '@grapecity/wijmo.grid';
import _ from 'lodash';
import { FC, useEffect, useRef } from 'react';

import { BaseFlexGridWindow } from './BaseFlexGridWindow';

export const DetailFlexGrid: FC<any> = (props) => {
  const { error } = props;

  const _flexgrid = useRef<grid.FlexGrid>();
  const _errorRef = useRef([]);

  const _tooltipRef = useRef(
    new wijmo.Tooltip({
      cssClass: 'wj-custom-alert-tooltip',
    })
  );

  useEffect(() => {
    _tooltipRef.current.dispose();
    _errorRef.current = error;
    if (_flexgrid.current) _flexgrid.current.collectionView.refresh();
  }, [error]);

  const newProps = {
    ...props,
    initialized: (s: grid.FlexGrid): void => {
      _flexgrid.current = s;
      props.initialized?.call(null, s);
    },
    formatItem: (s: grid.FlexGrid, e: any): void => {
      if (!_errorRef.current) return;
      if (e.panel == s.cells) {
        let errState = false;
        const err = _errorRef.current[e.row];
        const binding = s.columns[e.col].binding;
        if (err && binding && err[binding]) {
          _tooltipRef.current.setTooltip(e.cell, err[binding]['message']);
          errState = true;
        }
        wijmo.toggleClass(e.cell, 'wj-state-invalid', errState);
      }
      props.formatItem?.call(null, s);
    },
    updatingLayout: (s: grid.FlexGrid): void => {
      props.updatingLayout?.call(null, s);
    },
    updatedLayout: (s: grid.FlexGrid): void => {
      props.updatedLayout?.call(null, s);
    },
    allowAddNew: !props.notAllowAddNew,
    allowDelete: !props.notAllowDelete,
    keyActionEnter: 'CycleEditable',
    keyActionTab: 'CycleEditable',
  };

  return <BaseFlexGridWindow {...newProps}>{newProps.children}</BaseFlexGridWindow>;
};
