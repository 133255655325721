import { DataMap } from '@grapecity/wijmo.grid';

import { BusinessCode } from '@/business_code/types';

export const getDataMap = (codes: { label: string; value: string | number }[]) => {
  return new DataMap(codes, 'value', 'label');
};

export const getLabelFromValue = (v: string, codes: BusinessCode[]) => {
  for (let i = 0; i < codes.length; i++) {
    if (codes[i].value == v) return codes[i].label;
  }
  return '';
};

export const getValuelFromLabel = (l: string, codes: BusinessCode[]) => {
  for (let i = 0; i < codes.length; i++) {
    if (codes[i].label == l) return codes[i].value;
  }
  return '';
};
