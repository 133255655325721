import _ from 'lodash';

import { UpdateType } from '@/generated/graphql';

export const diffObject = (before: _.List<any>, after: _.List<any>, key: string) => {
  // STEP1.削除対象の取得
  const deleteObj = _.differenceBy(before, after, key);

  // STEP2.beforeから削除対象を除外
  before = _.differenceWith(before, deleteObj, _.isEqual);

  // STEP3.削除対象以外の差分取得（更新または登録）
  const updateInsertObj = _.differenceWith(after, before, _.isEqual);

  // STEP4.キーの有無で絞り込み、更新対象取得
  const updateObj = updateInsertObj.filter((x) => x[key]);

  // STEP5.差分より、登録対象取得
  const insertObj = _.differenceBy(updateInsertObj, updateObj);

  // STEP6.返却objectの作成
  const returnObj = {
    insert: insertObj,
    update: updateObj,
    delete: deleteObj,
  };

  return returnObj;
};

export const diffObject2 = (before: any[], after: any[]) => {
  const returnData = _.cloneDeep(after ?? []);
  returnData.forEach((data) => {
    const findData = _.find(before, { id: data['id'] });
    if (findData) {
      // 更新データ
      if (_.isEqual(data, findData)) {
        data['updateType'] = 'NONE';
      } else {
        data['updateType'] = UpdateType.Update;
      }
    } else {
      // 新規データ
      data['updateType'] = UpdateType.Insert;
    }
  });
  before.forEach((data) => {
    if (data['id']) {
      const findData = _.find(after, { id: data['id'] });
      if (!findData) {
        if (data['id'].startsWith('new-')) return;
        const deleteData = _.cloneDeep(data);
        deleteData['updateType'] = UpdateType.Delete;
        returnData.push(deleteData);
      }
    }
  });
  return returnData;
};
