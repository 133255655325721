export enum AppliedStatusEnum {
  PAST = '0',
  NOW = '1',
  FUTURE = '2',
}

export const AppliedStatus = [
  { label: '過去分のみ表示', value: AppliedStatusEnum.PAST },
  { label: '適用中のみ表示', value: AppliedStatusEnum.NOW },
  { label: '未来分のみ表示', value: AppliedStatusEnum.FUTURE },
];
